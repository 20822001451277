// src/pages/ExerciseDetails.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../components/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Exercise } from '../components/Exercise';
import { FaDumbbell } from 'react-icons/fa';

const ExerciseDetails: React.FC = () => {
  const { exerciseId } = useParams<{ exerciseId: string }>();
  const [exercise, setExercise] = useState<Exercise | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchExercise = async () => {
      setLoading(true);
      try {
        // Fetch exercise data
        const exerciseRef = doc(db, 'exercises', exerciseId!);
        const exerciseSnap = await getDoc(exerciseRef);

        if (exerciseSnap.exists()) {
          const exerciseData = {
            id: exerciseSnap.id,
            ...(exerciseSnap.data() as Omit<Exercise, 'id'>),
          } as Exercise;
          setExercise(exerciseData);
        } else {
          setError('Exercise not found.');
        }
      } catch (err) {
        console.error('Error fetching exercise:', err);
        setError('Failed to load exercise.');
      } finally {
        setLoading(false);
      }
    };

    fetchExercise();
  }, [exerciseId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  if (error || !exercise) {
    return (
      <div className="flex justify-center items-center h-screen">
        <h2 className="text-xl text-red-500">{error || 'Exercise not found.'}</h2>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      {/* Exercise Title */}
      <h1 className="text-4xl font-bold mb-4 text-center">{exercise.en.title}</h1>

      {/* Exercise Media and Details */}
      <div className="flex flex-col lg:flex-row lg:space-x-8 mb-6">
        {/* Exercise Image or Video */}
        <div className="lg:w-1/2">
          {exercise.video_URL ? (
            <div className="flex justify-center mb-6 lg:mb-0 lg:mt-8">
              <video
                src={exercise.video_URL}
                controls
                className="w-full h-auto max-h-[800px] rounded-lg shadow-md"
              />
            </div>
          ) : exercise.thumbnail_URL ? (
            <div className="flex justify-center mb-6 lg:mb-0">
              <img
                src={exercise.thumbnail_URL}
                alt={exercise.en.title}
                className="w-full h-auto rounded-lg shadow-md"
              />
            </div>
          ) : null}
        </div>

        {/* Exercise Details, Description, and Steps */}
        <div className="lg:w-1/2 flex flex-col items-center lg:items-start lg:mt-8">
          {/* Exercise Details */}
          <div className="mb-4">
            {/* Body Parts */}
            {exercise.en.body_parts && (
              <div className="flex flex-wrap justify-center lg:justify-start mb-4">
                {exercise.en.body_parts.map((part, index) => (
                  <span
                    key={index}
                    className="bg-green-100 text-green-800 text-sm font-medium mr-2 mb-2 px-3 py-1 rounded-full"
                  >
                    {part}
                  </span>
                ))}
              </div>
            )}

            {/* Equipment */}
            {exercise.en.equipment && (
              <p className="flex items-center text-gray-600 mb-4">
                <FaDumbbell className="inline-block mr-2" />
                Equipment: {exercise.en.equipment}
              </p>
            )}
          </div>

          {/* Exercise Description */}
          {exercise.en.description && (
            <div className="mb-8 max-w-3xl">
              <h2 className="text-2xl font-semibold mb-4">Description</h2>
              <p className="text-lg text-gray-700">{exercise.en.description}</p>
            </div>
          )}

          {/* Steps */}
          {exercise.en.steps && exercise.en.steps.length > 0 && (
            <div className="mb-8 max-w-3xl">
              <h2 className="text-2xl font-semibold mb-4">Steps</h2>
              <div className="text-lg text-gray-700 space-y-2">
                {exercise.en.steps.map((step, index) => (
                  <p key={index}>{step}</p>
                ))}
              </div>
            </div>
          )}

           {/* Common Mistakes */}
      {exercise.en.common_mistakes && (
        <div className="mb-8 max-w-3xl mx-auto">
          <h2 className="text-2xl font-semibold mb-4">Common Mistakes</h2>
          <ul className="list-disc list-inside text-lg text-gray-700 space-y-2">
            {exercise.en.common_mistakes}
          </ul>
        </div>
      )}

      {/* Tips */}
      {exercise.en.tips && (
        <div className="mb-8 max-w-3xl mx-auto">
          <h2 className="text-2xl font-semibold mb-4">Tips</h2>
          <ul className="list-disc list-inside text-lg text-gray-700 space-y-2">
            {exercise.en.tips}
          </ul>
        </div>
      )}
        </div>
      </div>

     
    </div>
  );
};

export default ExerciseDetails;
