// src/components/WorkoutCard.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Workout } from './Workout';
import { FaDumbbell } from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface WorkoutCardProps {
  workout: Workout;
}

const WorkoutCard: React.FC<WorkoutCardProps> = ({ workout }) => {
  return (
    <div
      key={workout.id}
      className="bg-white rounded-lg shadow-md overflow-hidden transform hover:scale-105 transition-transform duration-300"
    >
      <Link to={`/workouts/${encodeURIComponent(workout.id)}`}>
        <LazyLoadImage
          alt={workout.en.title}
          src={workout.workout_desc_img}
          effect="black-and-white"
          className="w-full h-48 object-cover"
        />
        <div className="p-4">
          <h2 className="text-xl font-semibold mb-2">{workout.en.title}</h2>
          <p className="text-gray-600 mb-2">
            <FaDumbbell className="inline-block mr-2 text-gray-500" />
            {workout.en.body_parts}
          </p>
          {/* Add more details as necessary */}
        </div>
      </Link>
    </div>
  );
};

export default WorkoutCard;
