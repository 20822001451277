// src/components/PlanCard.tsx
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Plan } from './Plan';
import { FaDumbbell, FaCalendarAlt } from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface PlanCardProps {
  plan: Plan;
}


const PlanCard: React.FC<PlanCardProps> = ({ plan }) => {
  return (
    <div
      key={plan.id}
      className="bg-white rounded-lg shadow-md overflow-hidden transform hover:scale-105 transition-transform duration-300"
    >
      <Link to={`/plans/${encodeURIComponent(plan.id)}`}>

        <LazyLoadImage
          alt={plan.en.title}
          src={plan.img_URL}
          effect="black-and-white"
          className="w-full h-48 object-cover"
        />
        <div className="p-4">
          <h2 className="text-xl font-semibold mb-2">{plan.en.title}</h2>
          <p className="text-gray-600 mb-2">
            <FaDumbbell className="inline-block mr-2 text-gray-500" />
            {plan.en.body_parts}
          </p>
          <p className="text-gray-600 mb-2">
            <FaCalendarAlt className="inline-block mr-2 text-gray-500" />
            Duration: {Object.keys(plan.en.levels).length} Weeks
          </p>
          <p className="text-gray-600 mb-2">
            <span className="font-semibold">Description:</span>{' '}
            {plan.en.category?.description}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default PlanCard;
