// src/pages/PlanDetails.tsx
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../components/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Plan } from '../components/Plan';
import { FaDumbbell, FaCalendarAlt } from 'react-icons/fa';

const PlanDetails: React.FC = () => {
  const { planId } = useParams<{ planId?: string }>();
  const [plan, setPlan] = useState<Plan | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPlan = async (id: string) => {
      try {
        const docRef = doc(db, 'plans', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          setPlan({ id: docSnap.id, ...(docSnap.data() as Omit<Plan, 'id'>) });
        } else {
          setError('Plan not found.');
        }
      } catch (err) {
        console.error('Error fetching plan:', err);
        setError('Failed to load plan details.');
      } finally {
        setLoading(false);
      }
    };

    if (planId) {
      fetchPlan(planId);
    } else {
      setError('No plan ID provided.');
      setLoading(false);
    }
  }, [planId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  if (error || !plan) {
    return (
      <div className="container mx-auto p-4">
        <h2 className="text-xl text-red-500">{error || 'Plan not found.'}</h2>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      {/* Plan Header */}
      <div className="flex flex-col md:flex-row md:items-center mb-6">
        <img
          src={plan.img_URL}
          alt={plan.en.title}
          className="w-full md:w-1/2 h-64 object-cover rounded-lg shadow-md"
        />
        <div className="md:ml-6 mt-4 md:mt-0">
          <h1 className="text-4xl font-bold mb-2">{plan.en.title}</h1>
          <p className="text-gray-600 mb-2">
            <FaDumbbell className="inline-block mr-2 text-gray-500" />
            {plan.en.body_parts}
          </p>
          <p className="text-gray-600 mb-2">
            <FaCalendarAlt className="inline-block mr-2 text-gray-500" />
            Duration: {Object.keys(plan.en.levels).length} Weeks
          </p>
          <p className="text-gray-600 mb-2">
            <span className="font-semibold">Description:</span>{' '}
            {plan.en.category?.description}
          </p>
        </div>
      </div>

      {/* Weeks */}
      <div className="mb-6">
        <h2 className="text-2xl font-semibold mb-4">Schedule</h2>
        {Object.entries(plan.en.levels)
          .sort(([aKey], [bKey]) => {
            // Extract numeric part from 'level_01', 'level_02', etc.
            const aNum = parseInt(aKey.replace('level_', ''), 10);
            const bNum = parseInt(bKey.replace('level_', ''), 10);
            return aNum - bNum;
          })
          .map(([levelKey, level]) => (
            <div key={levelKey} className="mb-8">
              <h3 className="text-xl font-semibold mb-2">
                 {level.title}
              </h3>
              <p className="text-gray-700 mb-4">{level.description}</p>

              {/* Days */}
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {Object.entries(level.days)
                  .sort(([aKey], [bKey]) => parseInt(aKey, 10) - parseInt(bKey, 10))
                  .map(([dayKey, day]) => (
                    <div
                      key={dayKey}
                      className="border rounded-lg p-4 shadow-sm bg-white"
                    >
                      <h4 className="text-lg font-semibold mb-1">
                       {day.title == "Rest today" ? dayKey.replace("00","")+" Day: Rest" : day.title}
                      </h4>
                      {/* Workouts */}
                      {day.workouts && (
                        <div className="mt-2">
                          {Object.entries(day.workouts)
                            .sort(([aKey], [bKey]) => parseInt(aKey, 10) - parseInt(bKey, 10))
                            .map(([workoutKey, workoutName]) =>
                              workoutName ? (
                                <Link
                                  key={workoutKey}
                                  to={`/workouts/${encodeURIComponent(workoutName)}`}
                                  className="text-blue-500 font-bold hover:underline block"
                                >
                                  {workoutName}
                                </Link>
                              ) : null
                            )}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PlanDetails;
