// src/components/ExerciseCard.tsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Exercise } from './Exercise';
import { FaDumbbell } from 'react-icons/fa';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface ExerciseCardProps {
  exercise: Exercise;
}

const ExerciseCard: React.FC<ExerciseCardProps> = ({ exercise }) => {
  return (
    <div
      className="bg-white rounded-lg shadow-md overflow-hidden transform hover:scale-105 transition-transform duration-300"
    >
      <Link to={`/exercises/${encodeURIComponent(exercise.id)}`}>
      <div className="flex justify-center">
  <LazyLoadImage
    alt={exercise.en.title}
    src={exercise.thumbnail_URL}
    effect="black-and-white"
    className="h-48 object-cover"
  />
</div>

        <div className="p-4">
          <h2 className="text-xl font-semibold mb-2">{exercise.en.title}</h2>
          <p className="text-gray-600 mb-2">
            <FaDumbbell className="inline-block mr-2 text-gray-500" />
           
            {exercise.en.body_parts && exercise.en.body_parts.join(', ')}
          </p>
          {/* Add more details as necessary */}
        </div>
      </Link>
    </div>
  );
};

export default ExerciseCard;
