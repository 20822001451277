// src/pages/WorkoutDetails.tsx
import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../components/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Workout } from '../components/Workout';
import { Exercise } from '../components/Exercise';
import { FaClock, FaFire, FaDumbbell } from 'react-icons/fa';

const WorkoutDetails: React.FC = () => {
  const { workoutId } = useParams<{ workoutId: string }>();
  const [workout, setWorkout] = useState<Workout | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [exercisesMap, setExercisesMap] = useState<{ [key: string]: Exercise }>({});

  useEffect(() => {
    const fetchWorkout = async () => {
      setLoading(true);
      try {
        // Fetch workout data
        const workoutRef = doc(db, 'workouts', workoutId!);
        const workoutSnap = await getDoc(workoutRef);

        if (workoutSnap.exists()) {
          const workoutData = {
            id: workoutSnap.id,
            ...(workoutSnap.data() as Omit<Workout, 'id'>),
          } as Workout;
          setWorkout(workoutData);

          // Process the sequence and fetch exercises
          await processSequence(workoutData);
        } else {
          setError('Workout not found.');
        }
      } catch (err) {
        console.error('Error fetching workout:', err);
        setError('Failed to load workout.');
      } finally {
        setLoading(false);
      }
    };

    const processSequence = async (workoutData: Workout) => {
      const sequenceItems = Object.entries(workoutData.sequence)
        .sort(([keyA], [keyB]) => parseInt(keyA) - parseInt(keyB))
        .map(([, value]) => value);

      // Collect exercise titles, excluding 'Rest'
      const exerciseTitles = sequenceItems
        .filter((item) => item.title && item.title.toLowerCase() !== 'rest')
        .map((item) => item.title);

      // Remove duplicates
      const uniqueExerciseTitles = Array.from(new Set(exerciseTitles));

      // Fetch exercises
      const exercisePromises = uniqueExerciseTitles.map(async (title) => {
        if (title) {
          const exerciseRef = doc(db, 'exercises', title);
          const exerciseSnap = await getDoc(exerciseRef);
          if (exerciseSnap.exists()) {
            return {
              id: exerciseSnap.id,
              ...(exerciseSnap.data() as Omit<Exercise, 'id'>),
            } as Exercise;
          }
          return null;
        }
      });

      const exercises = await Promise.all(exercisePromises);
      const exercisesMapTemp: { [key: string]: Exercise } = {};
      exercises.forEach((exercise) => {
        if (exercise) {
          exercisesMapTemp[exercise.id] = exercise;
        }
      });
      setExercisesMap(exercisesMapTemp);
    };

    fetchWorkout();
  }, [workoutId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  if (error || !workout) {
    return (
      <div className="flex justify-center items-center h-screen">
        <h2 className="text-xl text-red-500">{error || 'Workout not found.'}</h2>
      </div>
    );
  }

  // Process the sequence for rendering
  const sequenceItems = Object.entries(workout.sequence)
    .sort(([keyA], [keyB]) => parseInt(keyA) - parseInt(keyB))
    .map(([, value]) => value);

  return (
    <div className="container mx-auto p-4">
      {/* Workout Title */}
      <h1 className="text-4xl font-bold mb-4 text-center">{workout.en.title}</h1>

      {/* Workout Image */}
      {workout.workout_desc_img && (
        <div className="flex justify-center mb-6">
          <img
            src={workout.workout_desc_img}
            alt={workout.en.title}
            className="w-full max-w-2xl h-auto rounded-lg shadow-md"
          />
        </div>
      )}

      {/* Workout Details */}
      <div className="mb-8 flex flex-col items-center">
        {/* Body Parts */}
        {workout.en.body_parts_array && (
          <div className="flex flex-wrap justify-center mb-4">
            {workout.en.body_parts_array.map((part, index) => (
              <span
                key={index}
                className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 mb-2 px-3 py-1 rounded-full"
              >
                {part}
              </span>
            ))}
          </div>
        )}

        {/* Other Details */}
        <div className="flex flex-wrap justify-center space-x-6 text-gray-600">
          <p className="flex items-center">
            <FaClock className="inline-block mr-2" />
            Duration: {workout.total_minutes || 'N/A'} minutes
          </p>
          <p className="flex items-center">
            <FaFire className="inline-block mr-2" />
            Calories: {workout.calories || 'N/A'}
          </p>
          <p className="flex items-center">
            <FaDumbbell className="inline-block mr-2" />
            Difficulty: {workout.en.dif_level || 'N/A'}
          </p>
        </div>
      </div>

      {/* Workout Description */}
      {workout.en.description && (
        <p className="text-lg text-gray-700 mb-8 max-w-3xl mx-auto">{workout.en.description}</p>
      )}

      {/* Sequence of Exercises */}
      <h2 className="text-3xl font-semibold mb-6 text-center">Order of exercises</h2>
      <div className="space-y-4 max-w-3xl mx-auto">
        {sequenceItems.map((item, index) => {
          const isRest = item.title && item.title.toLowerCase() === 'rest';
          const repeats =
            item.repeats !== undefined
              ? item.repeats
              : exercisesMap[item.title]?.repeats;
          const duration =
            item.countdown !== undefined
              ? item.countdown
              : exercisesMap[item.title]?.countdown;
          const videoURL = item.video_URL || exercisesMap[item.title]?.video_URL;
          const thumbnailURL =
            item.thumbnail_URL || exercisesMap[item.title]?.thumbnail_URL;
          const exercise = exercisesMap[item.title];

          if (isRest) {
            // Rest item
            return (
              <div
                key={index}
                className="p-4 bg-yellow-100 rounded-lg shadow-md flex items-center"
              >
                <div className="w-16 h-16 flex-shrink-0 flex items-center justify-center">
                  <FaClock className="text-yellow-600 text-3xl" />
                </div>
                <div className="ml-4">
                  <p className="text-xl font-semibold text-yellow-800">
                    {item.title || 'Rest'}
                  </p>
                  <p className="text-gray-600">
                    Duration: {duration ? `${duration} seconds` : 'N/A'}
                  </p>
                </div>
              </div>
            );
          } else if (item.title && (repeats || videoURL)) {
            // Exercise item
            return (
              <div
                key={index}
                className="p-4 bg-white rounded-lg shadow-md flex items-center hover:bg-gray-50 transition duration-200"
              >
                <div className="w-16 h-16 flex-shrink-0">
                  {thumbnailURL ? (
                    <img
                      src={thumbnailURL}
                      alt={item.title}
                      className="w-full h-full object-cover rounded"
                    />
                  ) : (
                    <div className="w-full h-full bg-gray-200 rounded"></div>
                  )}
                </div>
                <div className="ml-4">
                  {exercise ? (
                    <Link
                      to={`/exercises/${encodeURIComponent(exercise.id)}`}
                      className="text-xl font-semibold text-blue-600 hover:underline"
                    >
                      {item.title}
                    </Link>
                  ) : (
                    <p className="text-xl font-semibold">{item.title}</p>
                  )}
                  <p className="text-gray-600">
                    {repeats !== undefined ? `Repeats: ${repeats}` : ''}
                    {duration !== undefined ? ` | Duration: ${duration} seconds` : ''}
                  </p>
                </div>
              </div>
            );
          } else {
            // Other types or missing data
            return (
              <div
                key={index}
                className="p-4 bg-gray-100 rounded-lg shadow-md flex items-center"
              >
                <p className="text-xl font-semibold">
                  {item.title || 'Unknown Item'}
                </p>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default WorkoutDetails;
