// src/App.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import PlanDetails from './pages/PlanDetails';
import WorkoutDetails from './pages/WorkoutDetails';
import ExerciseDetails from './pages/ExerciseDetails';

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/plans/:planId" element={<PlanDetails />} />
      <Route path="/workouts/:workoutId" element={<WorkoutDetails />} />
      <Route path="/exercises/:exerciseId" element={<ExerciseDetails />} />
      {/* Add more routes as needed */}
    </Routes>
  );
};

export default App;
