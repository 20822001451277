// src/pages/Home.tsx
import React, { useEffect, useState } from 'react';
import { db } from '../components/firebase';
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  DocumentData,
  QueryDocumentSnapshot,
  getCountFromServer,
} from 'firebase/firestore';
import { Plan } from '../components/Plan';
import { Workout } from '../components/Workout';
import { Exercise } from '../components/Exercise';
import PlanCard from '../components/PlanCard';
import WorkoutCard from '../components/WorkoutCard';
import ExerciseCard from '../components/ExerciseCard';

const Home: React.FC = () => {
    const validTabs = ['plans', 'workouts', 'exercises'] as const;

    // Get the initial tab from the URL hash
    const hashTab = window.location.hash ? window.location.hash.substring(1) : 'plans';
  
    // Set initial selectedTab
    const [selectedTab, setSelectedTab] = useState<'plans' | 'workouts' | 'exercises'>(
      validTabs.includes(hashTab as any) ? (hashTab as 'plans' | 'workouts' | 'exercises') : 'plans'
    );
  
    // Listen for URL hash changes
    useEffect(() => {
      const handleHashChange = () => {
        const hashTab = window.location.hash ? window.location.hash.substring(1) : 'plans';
        if (validTabs.includes(hashTab as any)) {
          setSelectedTab(hashTab as 'plans' | 'workouts' | 'exercises');
        }
      };
  
      window.addEventListener('hashchange', handleHashChange);
  
      return () => {
        window.removeEventListener('hashchange', handleHashChange);
      };
    }, []);
  
    // Update URL hash when selectedTab changes
    useEffect(() => {
      if (window.location.hash.substring(1) !== selectedTab) {
        window.location.hash = selectedTab;
      }
    }, [selectedTab]);

  // Plans State
  const [plans, setPlans] = useState<Plan[]>([]);
  const [plansLoading, setPlansLoading] = useState<boolean>(true);
  const [plansError, setPlansError] = useState<string | null>(null);
  const [plansCount, setPlansCount] = useState<number | null>(null);

  // Workouts State
  const [workouts, setWorkouts] = useState<Workout[]>([]);
  const [workoutsLoading, setWorkoutsLoading] = useState<boolean>(false);
  const [workoutsError, setWorkoutsError] = useState<string | null>(null);
  const [workoutsCount, setWorkoutsCount] = useState<number | null>(null);

  // Exercises State
  const [exercises, setExercises] = useState<Exercise[]>([]);
  const [exercisesLoading, setExercisesLoading] = useState<boolean>(false);
  const [exercisesError, setExercisesError] = useState<string | null>(null);
  const [exercisesCount, setExercisesCount] = useState<number | null>(null);
  const [exercisePages, setExercisePages] = useState<Exercise[][]>([]);
  const [exercisePageIndex, setExercisePageIndex] = useState<number>(0);
  const [exercisePageCursors, setExercisePageCursors] = useState<QueryDocumentSnapshot<DocumentData>[]>([]);


  // Fetch Counts
  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const plansSnapshot = await getCountFromServer(collection(db, 'plans'));
        setPlansCount(plansSnapshot.data().count);

        const workoutsSnapshot = await getCountFromServer(collection(db, 'workouts'));
        setWorkoutsCount(workoutsSnapshot.data().count);

        const exercisesSnapshot = await getCountFromServer(collection(db, 'exercises'));
        setExercisesCount(exercisesSnapshot.data().count);
      } catch (err) {
        console.error('Failed to get counts:', err);
      }
    };

    fetchCounts();
  }, []);

  // Fetch Plans
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'plans'));
        const plansData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as Omit<Plan, 'id'>),
        }));
        setPlans(plansData);
      } catch (err) {
        setPlansError('Failed to load plans.');
      } finally {
        setPlansLoading(false);
      }
    };

    fetchPlans();
  }, []);

  // Fetch Workouts
  useEffect(() => {
    const fetchWorkouts = async () => {
      setWorkoutsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'workouts'));
        const workoutsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as Omit<Workout, 'id'>),
        }));
  
        setWorkouts(workoutsData);
      } catch (err) {
        setWorkoutsError('Failed to load workouts.');
      } finally {
        setWorkoutsLoading(false);
      }
    };

    if (selectedTab === 'workouts' && workouts.length === 0 && !workoutsLoading) {
      fetchWorkouts();
    }
  }, [selectedTab, workouts.length, workoutsLoading]);

  // Fetch Exercises with Pagination
  const fetchExercises = async (direction: 'next' | 'prev' | 'start') => {
    setExercisesLoading(true);
    try {
      let newIndex = exercisePageIndex;

      if (direction === 'start') {
        // Reset pages, cursors, and index
        setExercisePages([]);
        setExercisePageCursors([]);
        newIndex = 0;
      } else if (direction === 'next') {
        newIndex = exercisePageIndex + 1;
      } else if (direction === 'prev') {
        newIndex = exercisePageIndex - 1;
      }

      if (exercisePages[newIndex]) {
        // Page is cached, use it
        setExercises(exercisePages[newIndex]);
        setExercisePageIndex(newIndex);
      } else {
        // Fetch new page
        let q;

        if (direction === 'start') {
          q = query(
            collection(db, 'exercises'),
            orderBy('normalizedName'),
            limit(20)
          );
        } else if (direction === 'next') {
          const lastCursor = exercisePageCursors[exercisePageIndex];
          if (!lastCursor) {
            console.log("No more exercises to fetch.");
            setExercisesLoading(false);
            return;
          }
          q = query(
            collection(db, 'exercises'),
            orderBy('normalizedName'),
            startAfter(lastCursor),
            limit(20)
          );
        } else {
          console.log("Invalid direction or missing cursor.");
          setExercisesLoading(false);
          return;
        }

        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const exercisesData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...(doc.data() as Omit<Exercise, 'id'>),
          }));
          setExercises(exercisesData);

          // Update the pages cache
          const newExercisePages = [...exercisePages];
          newExercisePages[newIndex] = exercisesData;
          setExercisePages(newExercisePages);

          // Update cursors
          const newExercisePageCursors = [...exercisePageCursors];
          newExercisePageCursors[newIndex] = querySnapshot.docs[querySnapshot.docs.length - 1];
          setExercisePageCursors(newExercisePageCursors);

          setExercisePageIndex(newIndex);
        } else {
          // No more documents
          setExercisesError('No more exercises.');
        }
      }
    } catch (err) {
      console.error('Failed to load exercises:', err);
      setExercisesError('Failed to load exercises.');
    } finally {
      setExercisesLoading(false);
    }
  };
  useEffect(() => {
    if (selectedTab === 'exercises' && exercises.length === 0 && !exercisesLoading) {
      fetchExercises('start');
    }
  }, [selectedTab]);
  
  return (
    <div className="container mx-auto p-4">
             <header className="flex justify-center items-center mb-6">
        <a
          href="https://kinestex.com"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-start space-x-2 text-3xl font-bold text-gray-800 hover:text-green-600"
        >
          <span>KinesteX SDK</span>
          {/* <img src={favicon} alt="KinesteX Logo" className="w-8 h-8" /> */}
        </a>
      </header>
      {/* Tab Buttons */}
      <div className="flex justify-center mb-6">
   
        <button
          onClick={() => setSelectedTab('plans')}
          className={`px-4 py-2 mx-1 font-semibold ${
            selectedTab === 'plans' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
          } rounded`}
        >
          Plans
        </button>
        <button
          onClick={() => setSelectedTab('workouts')}
          className={`px-4 py-2 mx-1 font-semibold ${
            selectedTab === 'workouts' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
          } rounded`}
        >
          Workouts
        </button>
        <button
          onClick={() => {
            setSelectedTab('exercises');
            if (exercises.length === 0 && !exercisesLoading) {
              fetchExercises('start');
            }
          }}
          className={`px-4 py-2 mx-1 font-semibold ${
            selectedTab === 'exercises' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
          } rounded`}
        >
          Exercises
        </button>
      </div>

      {/* Content */}
      {selectedTab === 'plans' && (
        <div>
          <h1 className="text-3xl font-bold mb-6">
            {plansCount !== null ? `${plansCount} Plans` : 'Plans'}
          </h1>
          {plansLoading ? (
            <div className="flex justify-center items-center h-screen">
              <div className="loader">Loading...</div>
            </div>
          ) : plansError ? (
            <div className="flex justify-center items-center h-screen">
              <h2 className="text-xl text-red-500">{plansError}</h2>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {plans.map((plan) => (
                <PlanCard key={plan.id} plan={plan} />
              ))}
            </div>
          )}
        </div>
      )}

      {selectedTab === 'workouts' && (
        <div>
          <h1 className="text-3xl font-bold mb-6">
            {workoutsCount !== null ? `${workoutsCount} Workouts` : 'Workouts'}
          </h1>
          {workoutsLoading ? (
            <div className="flex justify-center items-center h-screen">
              <div className="loader">Loading...</div>
            </div>
          ) : workoutsError ? (
            <div className="flex justify-center items-center h-screen">
              <h2 className="text-xl text-red-500">{workoutsError}</h2>
            </div>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {workouts.map((workout) => (
                <WorkoutCard key={workout.id} workout={workout} />
              ))}
            </div>
          )}
        </div>
      )}

{selectedTab === 'exercises' && (
        <div>
          <h1 className="text-3xl font-bold mb-6">
            {exercisesCount !== null ? `${exercisesCount} Exercises` : 'Exercises'}
          </h1>
          {exercisesLoading ? (
            <div className="flex justify-center items-center h-screen">
              <div className="loader">Loading...</div>
            </div>
          ) : exercisesError ? (
            <div className="flex justify-center items-center h-screen">
              <h2 className="text-xl text-red-500">{exercisesError}</h2>
            </div>
          ) : (
            <div>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6">
                {exercises.map((exercise) => (
                  <ExerciseCard key={exercise.id} exercise={exercise} />
                ))}
              </div>
              {/* Pagination Controls */}
              <div className="flex justify-center mt-6">
                <button
                  onClick={() => {
                    fetchExercises('prev');
                  }}
                  disabled={exercisePageIndex === 0}
                  className={`px-4 py-2 mx-1 font-semibold ${
                    exercisePageIndex === 0 ? 'bg-gray-200 text-gray-800 opacity-50 cursor-not-allowed' : 'bg-blue-500 text-white'
                  } rounded`}
                >
                  Previous
                </button>
                <span className="px-4 py-2 mx-1 font-semibold text-gray-800">
                  Page {exercisePageIndex + 1}
                </span>
                <button
                  onClick={() => {
                    fetchExercises('next');
                  }}
                  disabled={exercises.length < 20}
                  className={`px-4 py-2 mx-1 font-semibold ${
                    exercises.length < 20 ? 'bg-gray-200 text-gray-800 opacity-50 cursor-not-allowed' : 'bg-blue-500 text-white'
                  } rounded`}
                >
                  Next
                </button>
              </div>
            </div>
          )}
           </div>
      )}
    </div>
  );
};

export default Home;
