// src/firebase.ts
import { initializeApp } from 'firebase/app';
import { initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from 'firebase/firestore'; // Import Firestore

const firebaseConfig = {
    apiKey: "AIzaSyAvQUSXEm8EY-5mepj6bGBHJLztQQVPidc",
    authDomain: "aiwill-5f38d.firebaseapp.com",
    databaseURL: "https://aiwill-5f38d-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "aiwill-5f38d",
    storageBucket: "aiwill-5f38d.appspot.com",
    messagingSenderId: "657098592689",
    appId: "1:657098592689:web:57e205c3b22f875a7fed90",
    measurementId: "G-WZ5PGKM5FM"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and Storage
export const db = initializeFirestore(app, 
    {localCache: 
      persistentLocalCache(/*settings*/{tabManager: persistentMultipleTabManager()})
  });